import {request} from "./network";

// 登录
export function login(data) {
    return request({
        method: "post",
        url: "/login/test/teacher",
        data,
    });

}

//退出登录
export function logout(params) {
    return request({
        method: "post",
        url: "/login/teacher/logout",
        params,
    });
}

// 获取当前登录用户信息
export function loginUserInfo(name, data) {
    return request({
        method: "get",
        url: "/login/teacher/info" + name,
        data,
    });
}
