import axios from "axios";
import {
    SERVER_URL,
    DEVELOPMENT_SERVER_URL
} from '../../config/server'

import {
    Notification,
    Message
} from "element-ui";
import router from "../../router";

export function request(config) {
    const instance = axios.create({
        baseURL: process.env.NODE_ENV === "development" ?
            DEVELOPMENT_SERVER_URL : SERVER_URL,
        timeout: 60 * 1000,
    });

    instance.interceptors.request.use(
        (config) => {
            let token = localStorage.getItem("index-token");
            if (token) {
                config.headers.Authorization = "Bearer " + token;
            }
            return config;
        },
        (error) => {
            return Promise.reject(error);
        }
    );

    instance.interceptors.response.use(
        (res) => {
            console.log(res)
            if (res.data.code == 401) {
                // 未登录
                Notification({
                    message: res.data.message,
                    duration: 1500,

                });
                // 清除本地的 token
                window.localStorage.clear();

                //   console.log('error');
                return router.push("/login");


            }
            return res;
        },
        (error) => {
            console.log(error)
            Message.closeAll();
            // if (error.response.status === 401) {
            //   // 未登录
            //   Notification({
            //     message: "身份验证失败，请登录",
            //     duration: 1500,
            //     onClose() {
            //     //   console.log('error');
            //       router.push("/login");
            //     },
            //   });
            //   return false;
            // }
            return Promise.reject(error);
        }
    );

    return instance(config);
}

// 通用下载方法
export function download(url, params, filename) {
    console.log(url, params, filename)
    return instance.post(url, params, {
            transformRequest: [
                params => {
                    return tansParams(params);
                }
            ],
            headers: {
                "Content-Type": "application/x-www-form-urlencoded"
            },
            responseType: "blob"
        })
        .then(data => {
            const content = data;
            console.log(content)
            const blob = new Blob([content]);
            if ("download" in document.createElement("a")) {
                const elink = document.createElement("a");
                elink.download = filename;
                elink.style.display = "none";
                elink.href = URL.createObjectURL(blob);
                document.body.appendChild(elink);
                elink.click();
                URL.revokeObjectURL(elink.href);
                document.body.removeChild(elink);
            } else {
                navigator.msSaveBlob(blob, filename);
            }
        })
        .catch(r => {
            console.error(r);
        });
}
// 合并多个请求
export function allRequest(arrRequest) {
    return axios.all(arrRequest);
}