<template>
  <el-container>
    <router-view />
    <!-- <el-header height="auto"><Header></Header></el-header>
    <el-container>
      <el-container>
        <el-main>
          <router-view />
        </el-main>
      </el-container>
    </el-container>
    <el-footer><Footer></Footer></el-footer> -->
  </el-container>
</template>
<script>
import Header from "c/index/Header";
import Footer from "c/index/Footer";
import Aside from "c/index/Aside";
import {refreshToken} from "r/index/examstudentinfolist";

export default {
  name: "index-index",
  components: {
    Header,
    Footer,
    Aside,
  },
  data() {
    return {
      timeout:false,
    };
  },
  methods: {
    refreshToken() {
      let timeKey = 'index_monitor_timeEnd';
      let nowTime = new Date().getTime();
      let timeEnd = localStorage.getItem(timeKey);
      if (!timeEnd) {
        timeEnd = nowTime;
        localStorage.setItem(timeKey, timeEnd)
      }
      timeEnd = parseInt(timeEnd);
      //每秒定时检查倒计时
      this.timeout = setInterval(async () => {
        timeEnd = timeEnd - 1000;
        if (nowTime - timeEnd > 3600000) {
          let {data: res} = await refreshToken();
          this.timeout && clearInterval(this.timeout);
          if (res.code != 200) {
            localStorage.clear();
            await this.$router.replace('/login');
            return;
          }
          localStorage.removeItem(timeKey);
          localStorage.setItem("index-token", res.data.token);
          //this.refreshToken();
          return;
        }
      }, 1000);
    },
  },
  created() {
   // this.refreshToken();
  },
  destroyed() {
    this.timeout && clearInterval(this.timeout);
  }
};
</script>
<style scoped lang="scss">
.el-container {
  width: 100%;
  height: 100%;
  overflow: hidden;
  min-width: 1024px;
  box-sizing: border-box;
  
}
// .el-header {
//   padding: 0;
//   z-index: 1;
// }
// ::v-deep .el-main {
//   padding: 0;
// }
</style>
